.bonus_wrapper{
    justify-content: end;
    .bonus_container{
        margin-right: 70px;
        img{
            height: 130% !important;
        }
    }
}
.bonus_slider:before{
    opacity: 1 !important;
}
.footer-section.images{
    border-image: linear-gradient(0.25turn, #090e27, rgba(168, 215, 64, 0.48), #090e27) 1 0 1 0;
}
.jackpot_wrapper{
    background: #020433;
}
.jackpot.name{
    color: #d01718;
}
.promo_right {
    transform: scale(0.8);
}
.search_icon{
     @include recolor(#f1ffd7)
}
.jackpot.value{
    color: #ffffff;
}
.header-logo {
    width: 8vw;
    height: 85%;
}
.bonus_slider:before{
    background-size: cover;
}
.btn.bonus {
    right: 7vw;
    bottom: auto;
    top: auto;
    height: 70%;
}
@media screen and (max-width: 600px) {
    .btn.bonus {
        margin-top: 10px;
        right: auto;
        bottom: 30px;
        top: auto;
        height: initial;
    }
    .bonus_wrapper{
        justify-content: center;
        .bonus_container{
            margin-right: 0px;
        }
        .bonus_pic{
            img{
                margin-top: -35px;
            }

        }
    }
    .bonus_wrapper{
        img{
            position: absolute;
            right: initial !important;
            top: 10%;
            height: 25%;
        }
    }
}